import type { BaseTranslation } from '../i18n-types'

const fr: BaseTranslation = {
    order: 'Commande',
    orders_total: 'Total',
    orders_comment: 'Commentaire: {comment}',
    orders_display_info_no_deal: 'Hors formule',
    invoices_fee_created_at_label: "Date de création",
    invoice_fee_status_label: "Statut",
    invoice_fee_seller_amount_label: "Montant vendeur",
    invoice_fee_transaction_amount_label: "Montant transaction",
    invoice_fee_order_number_label: "Numéro de commande",
    invoice_fee_intent_id_label: "Numéro de paiement",
    invoice_fee_fee_amount_label: "Montant commission",
    invoice_fee_currency_label: "Devise",
    invoice_fee_payment_method_label: "Moyen de paiement",
    invoices_title: 'Facture',
    invoices_reference_label: "Reference: {invoice_reference}",
    invoices_contact_label: 'Client: {contact_name}',
    invoices_contact_email_label: "Email: {contact_email}",
    invoices_contact_phone_label: "Téléphone: {contact_phone}",
    invoices_date_label: "Commande du {order_date} à {order_hour}",
    invoices_generated_label: "Générée: {datetime}",
    invoices_page_label: "Page ",
    invoices_total_header_title: "Total",
    invoices_total_label: "Total",
    invoices_subtotal_label: "Sous total",
    invoices_quantity_header_title: "Quantité",
    invoices_item_header_title: "Article",
    invoices_registration_number: "SIRET: {registration_number}",
    invoices_tax_number: "TVA: {tax_number}",
    invoices_tax_rate: "TVA {tax_rate}%",
    invoices_note_payment: 'Paiement: {date} à {time}',
    invoices_note_latest_payment: 'Dernier paiement: {date} à {time}',
    invoices_note_title: 'Reçu',
    invoices_note_meal: "Repas",
    invoices_note_payment_id: "ID paiement n°{number}",
    invoices_note_tips: 'Dont pourboire',
    invoices_note_total: 'Total payé',
    invoices_order_title_header: 'Facture générée par Get Resto pour le compte de:',
    invoices_marketplace_fees_label: 'Commissions de paiements sur la période du \n{start} au {end}',
    invoices_marketplace_generated_for: 'Facture générée pour :',
    order_charge_service_fee: "Frais de service",
    order_charge_tip: "Pourboire",
    order_discount: "Remise",
    order_charge_delivery: "Frais de livraison",
    printers_order_receipt_title: "Reçu de commande",
    printers_payment_receipt_title: "Reçu de paiement",
    printers_payment_receipt_table: "Table {table}",
    printers_payment_receipt_payment_date: "Le {date} à {time}",
    printers_payment_receipt_paid_amount: "MONTANT = {amount}",
    printers_payment_receipt_tip_amount: "dont pourboire = {amount}",
    printers_payment_receipt_remaining_amount: "MONTANT RESTANT = {amount}",
    printers_payment_receipt_qrcode_title: "Payez via mobile",
    printers_payment_receipt_qrcode_subtitle: "Scannez, Devouvrez, Cagnottez",
    printers_orders_summary_title: "Relevé de commandes",
    printers_orders_summary_dates_title: "Dates",
    printers_orders_summary_dates_from: "Du {date}",
    printers_orders_summary_dates_to: "Au {date}",
    printers_orders_summary_service_types: "Services",
    printers_orders_summary_service_types_view: "Consultation",
    printers_orders_summary_service_types_eat_in: "Sur place",
    printers_orders_summary_service_types_collection: "À emporter",
    printers_orders_summary_service_types_delivery: "Livraison",
    printers_orders_summary_service_types_checkout: "Paiement",
    printers_orders_summary_total_title: "Total",
    printers_orders_summary_total_amount: "Commandé : {amount}",
    printers_orders_summary_total_tip: "Pourboire : {amount}",
    printers_orders_summary_details_title: "Détail",
    printers_orders_summary_details_date: "Heure",
    printers_orders_summary_details_table: "Table",
    printers_orders_summary_details_amount: "Total",
    printers_orders_summary_details_tip: "Pourboire",
    printers_receipt_order_default_item: "Commande de {amount}",
    printers_receipt_order_adjustment_item: "Divers",
    discounts_default_name: "Remise",
    discounts_description: "{value} sur {{number:toutes vos prochaines|votre prochaine|vos ?? prochaines}} {{number:commandes|commande|commandes}} {service_types}",
    discounts_service_type_view: "en consultation",
    discounts_service_type_eat_in: "sur place",
    discounts_service_type_collection: "à emporter",
    discounts_service_type_delivery: "en livraison",
    discounts_service_type_checkout: "en paiement",
    discounts_service_type_or_join: " ou ",
    discount_name_free: "Offert: {name}",
    discount_name_default: "Remise {discount_value} sur {name}",
    discount_name_global: "Remise {discount_value} sur le total",
    printers_receipt_service_type_eat_in: "Sur place",
    printers_receipt_service_type_collection: "A emporter",
    printers_receipt_service_type_delivery: "Livraison",
    printers_receipt_service_type_checkout: "Paiement",
    location_company_info_not_specified: "Non renseigné",
    catalog_merge_default_new_category_name: "Nouveautés",
    printers_receipt_payment_status_paid: "Payé",
    printers_receipt_payment_status_pending: "En attente",
    printers_receipt_payment_status_refunded: "Remboursé",
    printers_receipt_payment_status_unpaid: "Non payé",
    printers_receipt_payment_status_failed: "Échoué",
    printers_receipt_payment_status_partially_paid: "Partiellement payé",
    printers_receipt_delivery_manager_phone_number: "Responsable: {manager_phone_number}",
    printers_receipt_payment_end_prep_time: "Fin de préparation : {time}",
    charges_category_title: "charges",
    charge_delivery: "Livraison",
    charge_service_fee: "Service",
    charge_tip: "Pourboire",
}

export default fr
