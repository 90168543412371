import { Box, Typography, useTheme } from '@mui/material';
import log from 'loglevel';
import { DateTime } from 'luxon';
import React, { CSSProperties, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DeviceContext } from '../../App';
import DefaultImage from '../../assets/aperitif.png';
import { Card } from '../../Common/StyledComponents';
import { Deal, Tags } from '../../my-lemonade-library/model/Catalog';
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt';
import { ProductExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import { Money, MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';
import { canOrderEntityNow } from '../../my-lemonade-library/src/restrictions/services/RestrictionsService';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import tagService, { MAX_TAGS_DISPLAY } from '../../tags/services/tagService';
import { HEIGHT_IMAGE_PRODUCT_ITEM_LIST, SM_WIDTH_IMAGE_PRODUCT_ITEM_LIST, XS_WIDTH_IMAGE_PRODUCT_ITEM_LIST } from '../configs/styleConfig';
import '../pages/ProductsPage.css';
import { getCharacterLengthToDisplay, truncateText } from '../services/TruncateTextService';

interface ProductsItemListComponentProps {
    item: ProductExtended | DealExt,
    categoryIcon: any,
    width?: number,
    openModal: (item: ProductExtended | Deal) => void,
    languages: string[],
    currentLang: string,
    catalogLang?: string,
    isWithoutImage: boolean;
}

const ProductsItemListComponent: React.FC<ProductsItemListComponentProps> = (props) => {

    const { item, categoryIcon, width, openModal, languages, catalogLang, isWithoutImage } = props;

    let locale = navigator.language.split('-')[0]

    const intl = useIntl();
    const theme = useTheme();
    const { mobile_device, tablet_landscape, desktop_device } = React.useContext(DeviceContext)

    const { selectedLocation, selectedCatalog, selectedTable } = useTypedSelector((state: RootState) => state.locations);

    const canOrderEntityNowResult = canOrderEntityNow(selectedLocation, selectedCatalog, item, selectedTable?.service_type, DateTime.now());

    const deal = item as DealExt;
    const isDeal = deal.lines && deal.lines.length > 0;

    const itemListMargin = theme.spacing(1)

    const getItemListSize = (width: number) => {

        let maxItemRow = 1

        if (tablet_landscape || desktop_device) {
            maxItemRow = 2
        }

        let marginFactor = 2 * maxItemRow
        return `${((width - parseInt(itemListMargin) * marginFactor) / maxItemRow)}px`
    }

    const getDealOrProductNameTranslationKey = (thisProduct: ProductExtended): string => {

        const finalStr: string | undefined = isDeal
            ? translationService.getDealNameTranslationKey(deal)
            : translationService.getProductNameTranslationKey(thisProduct);

        if (finalStr) {
            return finalStr;
        }
        else {
            return "";
        }
    }

    const getDealOrProductDescriptionTranslationKey = (thisProduct: ProductExtended): string => {

        const finalStr: string | undefined = isDeal
            ? translationService.getDealDescriptionTranslationKey(deal)
            : translationService.getProductDescriptionTranslationKey(thisProduct);

        if (finalStr) {
            return finalStr;
        }
        else {
            return "";
        }
    }

    const getProductTranslation = (translationKey: string, name: string | undefined): string => {

        if (intl.messages[translationKey]) {
            return intl.formatMessage({ id: translationKey });
        }

        log.debug(`Missing translation for product (key: ${translationKey})`);
        return name ?? "";
    }

    const product = item as ProductExtended;
    const dataTestPrefix = isDeal ? "deal_" : "product_";

    const price = useMemo(() => {

        let unformattedPrice: Money | undefined;

        if (isDeal) {
            unformattedPrice = deal.starting_price;
        }
        else if (product.skus && product.skus.length > 0) {
            unformattedPrice = product.skus[0].reduced_price ?? product.skus[0].price;
        }

        if (unformattedPrice) {
            return MoneyToStringWithSymbol(unformattedPrice);
        }
    }, [deal.starting_price, isDeal, product.skus]);

    return (

        <Card
            display={'flex'}
            minHeight="120px"
            width={width ? getItemListSize(width) : "100%"}
            m={1}
            alignItems="stretch"
            data-test={`${dataTestPrefix}${item.ref}`}
            style={{
                backgroundColor: theme.palette.background.paper,
                border: theme.palette.background.paper === theme.palette.background.default ? `solid 1px ${theme.palette.divider}` : "none",
                borderRight: "none", position: "relative", cursor: "pointer",
                opacity: canOrderEntityNowResult.canOrderEntityNow ? 1 : 0.4,
            }}
            onClick={() => {
                openModal(item);
            }}
        >
            <Box
                padding={1}
                display="flex"
                flexDirection="column"
                width={1}
                flex={1}
                justifyContent="space-between"
                sx={{
                    overflowY: "auto",
                }}
            >

                <Typography variant="h5" color="textSecondary">
                    {truncateText(
                        getProductTranslation(getDealOrProductNameTranslationKey(product), product.name),
                        getCharacterLengthToDisplay(mobile_device, "name"),
                    )}
                </Typography>

                {
                    // TODO: fix description tags
                    // Use case: the language is supported by the app but not by the catalog
                }
                {!languages.includes(locale) && (catalogLang && catalogLang !== locale)

                    ? <Box style={{ overflowWrap: "anywhere" }} >
                        {item && item.description_tags?.map((descriptionTag, idx) => {

                            return (
                                <Box
                                    key={idx}
                                    display="inline-block"
                                    borderRadius="15px"
                                    m="2px 4px 2px 0"
                                    p="4px"
                                    style={{
                                        border: ".1em solid",
                                        borderColor: theme.palette.primary.main
                                    }}
                                >
                                    <Typography color="primary">
                                        {  // TODO: Translate
                                            descriptionTag
                                        }
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Box>

                    : <Typography  /*TODO: Define a length of description instead of height*/
                        variant="caption"
                        style={{
                            overflowWrap: "anywhere",
                            display: "inline-block",
                            overflow: 'hidden',
                            color: theme.palette.text.disabled
                        }}
                    >
                        {item.description &&
                            truncateText(
                                getProductTranslation(getDealOrProductDescriptionTranslationKey(product), product.description),
                                getCharacterLengthToDisplay(mobile_device, "description", (item.tags && item.tags.length > 0)),
                            )
                        }
                    </Typography>
                }

                {item.tags && item.tags.length > 0 &&

                    <Box display="flex">
                        {item.tags.map((tag: Tags, index: number) => {

                            if (index < MAX_TAGS_DISPLAY) {

                                return (
                                    <Box key={index} >
                                        {tagService.renderTag(tag, theme.palette.secondary.main, false)}
                                    </Box>
                                );
                            }
                            else if (index === MAX_TAGS_DISPLAY) {

                                return (
                                    <Typography
                                        key={index}
                                        color="secondary"
                                        variant="h3"
                                        style={{ marginLeft: theme.spacing(1) }}
                                    >
                                        ...
                                    </Typography>
                                )
                            }

                            return null;
                        })}
                    </Box>
                }

                <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                >
                    {price &&
                        <Typography
                            variant="subtitle1"
                            color="primary"
                            sx={{
                                textDecoration: canOrderEntityNowResult.canOrderEntityNow ? "none" : 'line-through',
                            }}
                        >
                            {
                                (isDeal && deal.starting_price !== deal.max_price) ?
                                    intl.formatMessage({ id: "deal.deal_starting.price.text" }, { price })
                                    : price
                            }
                        </Typography>
                    }

                    {!canOrderEntityNowResult.canOrderEntityNow &&
                        <Typography
                            color={theme.palette.text.disabled}
                            data-test="unavailable_text"
                        >
                            {intl.formatMessage({ id: 'product.disable' })}
                        </Typography>
                    }
                </Box>
            </Box>

            {!isWithoutImage &&
                <Box
                    width={{ xs: XS_WIDTH_IMAGE_PRODUCT_ITEM_LIST, sm: SM_WIDTH_IMAGE_PRODUCT_ITEM_LIST }}
                    height={HEIGHT_IMAGE_PRODUCT_ITEM_LIST}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        backgroundColor: theme.palette.grey[200]
                    }}
                >
                    {item.image
                        ? <img width="100%" height="100%" style={imgStyle} src={item.image} alt='' />
                        : categoryIcon
                            ? categoryIcon.includes("svg")
                                ? <img width="50%" height="50%" src={categoryIcon} alt='' />
                                : <img width="100%" height="100%" style={imgStyle} src={categoryIcon} alt='' />
                            : <img width="100%" height="100%" style={imgStyle} src={DefaultImage} alt='' />
                    }
                </Box>
            }
        </Card>
    );
}

export default ProductsItemListComponent;

const imgStyle: CSSProperties = {
    objectFit: "cover",
    objectPosition: "center" /* Center the image within the element */
}